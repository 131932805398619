"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configTemplates = void 0;
/**
 * Configuration templates
 *
 * All the default values for config options throughout kiwi are set here, under
 * the 'default' template object below.
 * If a 'default.template' value is set in the user config, this is used to
 * find the next template object below and is merged over the default config
 * template.
 * The user config (config.json by default) is then merged over the resulting
 * config object.
 *
 * The advanced settings dialog also displays these settings by iterating
 * through the keys. The values must not be null otherwise they will not be
 * shown.
 */

var configTemplates = {
  default: {
    plugins: [],
    windowTitle: 'Kiwi IRC - The web IRC client',
    useMonospace: false,
    language: '',
    theme: 'Default',
    themes: [{
      name: 'Default',
      url: 'static/themes/default'
    }],
    // Restricted to a single IRC server
    restricted: true,
    // The startup screen
    startupScreen: 'customServer',
    // Where to find the kiwi server
    kiwiServer: '/webirc/kiwiirc/',
    // If active, all connections will be routed via this BNC server. Network settings
    // will be read and updated to the BNC as they are changed.
    bnc: {
      active: false,
      server: '',
      port: 6667,
      tls: false,
      path: '',
      username: '',
      password: ''
    },
    warnOnExit: true,
    quitMessage: 'Page closed',
    // Default buffer settings
    buffers: {
      messageLayout: 'modern',
      alert_on: 'highlight',
      timestamp_format: '%H:%M:%S',
      // If timestamp_full_format is falsy, the browsers locale date format will be used
      timestamp_full_format: '',
      show_timestamps: true,
      scrollback_size: 250,
      show_hostnames: false,
      show_joinparts: true,
      show_topics: true,
      show_topic_in_header: false,
      show_nick_changes: true,
      show_mode_changes: true,
      show_presence_changes: true,
      traffic_as_activity: false,
      server_as_activity: true,
      coloured_nicklist: true,
      colour_nicknames_in_messages: true,
      block_pms: false,
      show_emoticons: true,
      extra_formatting: true,
      mute_sound: false,
      hide_message_counts: false,
      show_realnames: false,
      default_ban_mask: '*!%i@%h',
      default_kick_reason: 'Your behaviour is not conducive to the desired environment.',
      shared_input: false,
      show_message_info: true,
      who_loop: true,
      share_typing: true,
      // flash_title: message/highlight/off
      flash_title: 'message',
      nicklist_avatars: false,
      show_link_previews: true,
      inline_link_auto_previews: true,
      inline_link_auto_previews_query: true,
      inline_link_auto_preview_whitelist: '.*',
      show_buffer_groups: true,
      // prompt_leave: all/channels/queries/none
      prompt_leave: 'channels',
      // auto_request_history: all/channels/queries/none
      auto_request_history: 'channels'
    },
    // Startup screen default
    startupOptions: {
      server: '',
      port: 6667,
      tls: false,
      channel: '',
      nick: 'kiwi_?',
      direct: false,
      state_key: 'kiwi-state',
      remember_buffers: true,
      nick_format: ''
    },
    skipHiddenMessages: false,
    autoReconnect: false,
    disconnectOnSaslFail: true,
    allowRegisterProtocolHandler: false,
    noticeActiveBuffer: true,
    nicklistGroupAway: false,
    showChanlistModes: false,
    showAutocomplete: true,
    autocompleteTokens: ['/', '#', '@'],
    forceShowEmojiPicker: false,
    showEmojiPicker: true,
    showColorPicker: false,
    showSendButton: false,
    showAwayStatusIndicators: true,
    sidebarDefault: 'nicklist',
    showRaw: false,
    useBufferHistory: true,
    changeNickOnCollision: true,
    hideSettings: null,
    highlights: '',
    teamHighlights: false,
    aliases: "\n# General aliases\n/p /part $1+\n/me /action $destination $1+\n/j /join $1+\n/q /query $1+\n/w /whois $1+\n/raw /quote $1+\n/connect /server $1+\n/disconnect /quit $1+\n/cycle $channel? /lines /part $channel | /join $channel\n/active /back $1+\n/umode /mode $nick $1+\n/beso /action $destination :kiss $1+\n/besito /action $destination :besito $1+\n/abrazo /action $destination :abrazo $1+\n/hola /msg $channel Hola $1 :hola\n/manos /action $destination :handshake $1+\n/clap /msg $channel :aplauso\n\n# Op related aliases\n/op /quote mode $channel +o $1+\n/deop /quote mode $channel -o $1+\n/hop /quote mode $channel +h $1+\n/dehop /quote mode $channel -h $1+\n/voice /quote mode $channel +v $1+\n/devoice /quote mode $channel -v $1+\n/k /kick $channel $1+\n/bans /mode $channel +b\n/ban /quote mode $channel +b $1+\n/unban /quote mode $channel -b $1+\n\n# Misc aliases\n/slap /me slaps $1 around a bit with a large trout\n/tick /msg $channel \u2714",
    embedly: {
      Key: ''
    },
    /* eslint-disable quote-props */
    emojis: {
      '-___-': '1f611.png',
      ':\'-)': '1f602.png',
      '\':-)': '1f605.png',
      '\':-D': '1f605.png',
      '>:-)': '1f606.png',
      '\':-(': '1f613.png',
      '>:-(': '1f620.png',
      ':\'-(': '1f622.png',
      '-__-': '1f611.png',
      ':\')': '1f602.png',
      ':-D': '1f603.png',
      '\':)': '1f605.png',
      '\'=)': '1f605.png',
      '\':D': '1f605.png',
      '\'=D': '1f605.png',
      '>:)': '1f606.png',
      '\':(': '1f613.png',
      '\'=(': '1f613.png',
      ':-*': '1f618.png',
      ':^*': '1f618.png',
      '>:P': '1f61c.png',
      'X-P': '1f61c.png',
      '>:[': '1f61e.png',
      ':-(': '1f61e.png',
      ':-[': '1f61e.png',
      '>:(': '1f620.png',
      ':\'(': '1f622.png',
      ';-(': '1f622.png',
      '#-)': '1f635.png',
      '%-)': '1f635.png',
      'X-)': '1f635.png',
      '-_-': '1f611.png',
      ':-)': '1f642.png',
      ':D': '1f603.png',
      '=D': '1f603.png',
      '*)': '1f609.png',
      ':*': '1f618.png',
      '=*': '1f618.png',
      ':(': '1f61e.png',
      ':[': '1f61e.png',
      '=(': '1f61e.png',
      ':@': '1f620.png',
      ';(': '1f622.png',
      'D:': '1f628.png',
      '#)': '1f635.png',
      '%)': '1f635.png',
      'X)': '1f635.png',
      ':)': '1f642.png',
      '=]': '1f642.png',
      '=)': '1f642.png',
      ':]': '1f642.png',
      ':abrazo': 'abrazo.gif',
      ':abuela': 'abuela.gif',
      ':angel': 'angel.gif',
      ':aplaudo': 'clap.gif',
      ':aplauso': 'aplauso.gif',
      ':argentina': 'argentina.gif',
      ':auri': 'auriculares.gif',
      ':ayyy': 'ayyy.gif',
      ':baba': 'baba.gif',
      ':baby': 'baby.gif',
      ':banana': 'banana.gif',
      ':bart': 'bart.gif',
      ':bender': 'bender.gif',
      ':besito': 'besito.gif',
      ':beso': 'beso.gif',
      ':boca': 'boca2.gif',
      ':bocacerrada': 'bocacerrada.gif',
      ':bomba': 'bomba.gif',
      ':bow': 'bow.gif',
      ':brindis': 'brindis.gif',
      ':bruja': 'bruja01.gif',
      ':burger': 'burger.gif',
      ':/': 'confundido.gif',
      ':cafe': 'coffee.gif',
      ':cammy': 'cammy.gif',
      ':canchero': 'canchero.gif',
      ':caraenojada': 'angryface.gif',
      ':cerveza': 'cerveza.gif',
      ':chunli': 'chunli.gif',
      ':colaconejo': 'colaconejo.gif',
      ':cool': 'cool.gif',
      ':conejoa': 'abrazoconejo.gif',
      ':dancer': 'dancer.gif',
      ':dedoarriba': 'dedoarriba.gif',
      ':dedosjuntos': 'dedosjuntos.gif',
      ':dedosx': 'dedosx.gif',
      ':diez': 'pantera10.gif',
      ':discodancer': 'discodancer.gif',
      ':duda': 'duda.gif',
      '<3': 'corazon.gif',
      '</3': 'corazonr.gif',
      ':corazones': 'doscorazones.gif',
      ':estudiantes': 'estudiantes.gif',
      ':fernet': 'fernet.gif',
      ':fiesta': 'fiesta.gif',
      ':flanders': 'flanders.gif',
      ':flor': 'flor.gif',
      ':fuego': 'fuego.gif',
      ':fuerte': 'fuerte.gif',
      ':gallina': 'gallina.gif',
      ':golpear': 'golpear.gif',
      ':golpear2': 'golpear2.gif',
      ':gracias': 'gracias.gif',
      ';)': 'guinio.gif',
      ';p': 'guinio2.gif',
      ':handshake': 'hands.gif',
      ':blabla': 'hablasmucho.gif',
      ':hablasmucho': 'hablasmucho.gif',
      ':hero': 'hero.gif',
      ':hola': 'hola.gif',
      ':idea': 'idea.gif',
      ':insultar': 'insultar.gif',
      ':ken': 'ken.gif',
      ':kick': 'kick.gif',
      ':kiss': 'kiss.gif',
      ':knight': 'knight.gif',
      ':lamb': 'lamb2.gif',
      ':llanto': 'llanto.gif',
      ':p': 'lenguaafuera.gif',
      ':llorar': 'llorar.gif',
      ':llororisa': 'llorarrisa.gif',
      ':los5': 'los5.gif',
      ':loser': 'loser.gif',
      ':manosa': 'manosa.gif',
      ':manosc': 'manosc.gif',
      ':maquillaje': 'maquillaje.gif',
      ':mario': 'mario.gif',
      ':mariposa': 'mariposa.gif',
      ':mate': 'mate.gif',
      ':meaburre': 'meaburre.gif',
      ':miedo': 'miedo.gif',
      ':mmm': 'mmm.gif',
      'mnose': 'mnose.gif',
      ':mnose': 'mnose.gif',
      ':moco': 'moco.gif',
      ':mono': 'mono.gif',
      ':music': 'music.gif',
      ':nerd': 'nerd.gif',
      ':ninja': 'ninja.gif',
      ':nnn': 'nnn.gif',
      ':nohables': 'nohables.gif',
      ':nomiro': 'nomiro.gif',
      ':nooo': 'nooo.gif',
      ':nuu': 'nuu.gif',
      ':ohyeah': 'ohyeah.gif',
      ':ojosrisa': 'ojosrisa.gif',
      ':ok': 'ok.gif',
      ':paltas': 'paltas.gif',
      ':pensar': 'pensar.gif',
      ':perdon': 'perdon.gif',
      ':perro': 'perro.gif',
      ':piensa': 'piensaa.gif',
      ':piensaa': 'piensaa.gif',
      ':pingbeso': 'pingbeso.gif',
      ':pinguino': 'pinguino.gif',
      ':pollorock': 'pollorock.gif',
      ':popcorn': 'popcorn.gif',
      ':porrista': 'porrista.gif',
      ':rezar': 'rezar.gif',
      ':riemono': 'riemono.gif',
      ':risita': 'risita.gif',
      ':river': 'river2.gif',
      ':carp': 'carp.gif',
      ':rcard': 'rcard.gif',
      ':robot': 'robot.gif',
      ':rock': 'rock.gif',
      ':rocket': 'rocket.gif',
      ':sandwich': 'sandwich.gif',
      ':selfie': 'selfie.gif',
      ':senialar': 'senialar.gif',
      ':serpiente': 'serpiente.gif',
      ':shhh': 'shush.gif',
      ':sii': 'si.gif',
      ':silbar': 'silbar.gif',
      ':sirena': 'sirena.gif',
      ':skate': 'skate.gif',
      ':O': 'sorpresa.gif',
      ':sol': 'sol.gif',
      ':sospecha': 'sospecha.gif',
      ':susto': 'susto.gif',
      ':stop': 'stop.gif',
      ':telefono': 'telefono.gif',
      ':temor': 'temor.gif',
      ':teveo': 'teveo.gif',
      ':twerk': 'twerk.gif',
      ':unicornio': 'unicornio.gif',
      ':verguenza': 'verguenza.gif',
      '(y)': 'yes.gif',
      ':yoga': 'yoga2.gif',
      'xD': 'xd.gif',
      'XD': 'xd.gif'
    },
    emojiLocation: 'https://kiwiirc.com/shared/emoji/',
    textFormats: {
      user: '%nick',
      user_full: '%nick (%username@%host)',
      channel_join: '→ %text',
      channel_part: '← %text (%reason)',
      channel_quit: '← %text (%reason)',
      channel_kicked: '← %text (%reason)',
      channel_selfkick: '× %text (%reason)',
      channel_badpassword: '× %text',
      channel_topic: 'ⓘ %text',
      channel_banned: '× %text',
      channel_badkey: '⚠ %text',
      channel_inviteonly: '⚠ %channel %text',
      channel_alreadyin: '⚠ %nick %text',
      channel_limitreached: '⚠ %channel %text',
      channel_invalid_name: '⚠ %channel %text',
      channel_topic_setby: 'ⓘ %text',
      channel_has_been_invited: 'ⓘ %nick %text',
      server_connecting: '%text',
      server_connecting_error: '%text',
      mode: 'ⓘ %text',
      selfmode: 'ⓘ %nick %text',
      nickname_alreadyinuse: '⚠ %text',
      network_disconnected: '%text',
      network_connected: '%text',
      whois_channels: '%text',
      whois_idle_and_signon: '%text',
      whois_away: '%text',
      whois_server: '%text',
      whois_idle: '%text',
      whois_notfound: 'ⓘ %text',
      nick_changed: 'ⓘ %text',
      applet_notfound: '⚠ %text',
      encoding_changed: 'ⓘ %text',
      encoding_invalid: '⚠ %text',
      settings_saved: 'ⓘ %text',
      ignore_title: '%text:',
      ignore_none: '%text',
      ignore_nick: '%text',
      ignore_stop_notice: '%text',
      ignore_stopped: '%text',
      chanop_privs_needed: '⚠ %text',
      no_such_nick: 'ⓘ %nick: %text',
      unknown_command: 'ⓘ %text',
      motd: '%text',
      ctcp_response: '[CTCP %nick reply] %message',
      ctcp_request: '[CTCP %nick] %message',
      privmsg: '%text',
      notice: '📌  %text',
      action: '* %nick %text',
      whois_ident: '%nick [%nick!%ident@%host] * %text',
      whois_error: '[%nick] %text',
      whois: '%text',
      whowas_ident: 'was [%nick!%ident@%host] * %name',
      whowas_server: 'using %server (%info)',
      whowas_error: '[%nick] %text',
      who: '%nick [%nick!%ident@%host] * %realname',
      quit: '%text',
      rejoin: '%text',
      set_setting: 'ⓘ %text',
      list_aliases: 'ⓘ %text',
      ignored_pattern: 'ⓘ %text',
      wallops: '[WALLOPS] %text',
      message_nick: '%prefix%nick',
      general_error: '⚠ %text'
    },
    presetNetworks: []
  },
  // Config template for those hardcore irc veterans
  irc: {
    showEmojiPicker: false,
    sidebarDefault: 'nicklist',
    buffers: {
      messageLayout: 'inline',
      show_hostnames: true,
      coloured_nicklist: false,
      colour_nicknames_in_messages: false,
      show_emoticons: false,
      show_message_info: false,
      share_typing: false,
      inline_link_auto_previews: false
    }
  },
  // Config template for working as part of a team
  team: {
    teamHighlights: true,
    buffers: {
      messageLayout: 'modern',
      show_joinparts: false,
      show_nick_changes: true,
      show_mode_changes: true,
      show_realnames: true
    }
  }
};
exports.configTemplates = configTemplates;