"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-appsettings"
  }, [_c('div', {
    staticClass: "kiwi-appsettings-close",
    on: {
      "click": _vm.closeSettings
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('close')))]), _c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('form', {
    staticClass: "u-form"
  }, [_c('tabbed-view', {
    ref: "tabs",
    staticClass: "kiwi-appsettings-tab-container"
  }, [_c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('settings_general'),
      "focus": true,
      "name": "general"
    }
  }, [_c('div', {
    staticClass: "kiwi-appsettings-block"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('settings_general')))]), _c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-general"
  }, [_c('label', {
    staticClass: "kiwi-appsettings-setting-language"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_language')))]), _c('div', [_c('i', {
    staticClass: "fa fa-globe"
  })]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingLanguage,
      expression: "settingLanguage"
    }],
    on: {
      "change": function change($event) {
        var _context;
        var $$selectedVal = (0, _map.default)(_context = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.settingLanguage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" Auto ")]), _vm._l(_vm.localesList, function (l) {
    return _c('option', {
      key: l[0],
      domProps: {
        "value": l[0]
      }
    }, [_vm._v(" " + _vm._s(l[1]) + " ")]);
  })], 2)]), _c('label', {
    staticClass: "kiwi-appsettings-setting-theme"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_theme')) + " ")]), _c('a', {
    staticClass: "kiwi-appsettings-theme-reload",
    attrs: {
      "title": _vm.$t('refresh_theme')
    },
    on: {
      "click": _vm.refreshTheme
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.theme,
      expression: "theme"
    }],
    on: {
      "change": function change($event) {
        var _context2;
        var $$selectedVal = (0, _map.default)(_context2 = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context2, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.theme = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.settings.themes, function (t) {
    return _c('option', {
      key: t.name,
      domProps: {
        "value": t.name
      }
    }, [_vm._v(" " + _vm._s(t.name) + " ")]);
  }), 0)]), _vm.theme === 'custom' ? _c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('settings_themeurl')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customThemeUrl,
      expression: "customThemeUrl"
    }],
    staticClass: "u-input",
    domProps: {
      "value": _vm.customThemeUrl
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.customThemeUrl = $event.target.value;
      }
    }
  })]) : _vm._e(), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_show_autocomplete')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShowAutoComplete,
      expression: "settingShowAutoComplete"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShowAutoComplete) ? _vm._i(_vm.settingShowAutoComplete, null) > -1 : _vm.settingShowAutoComplete
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShowAutoComplete,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShowAutoComplete = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context3;
            $$i > -1 && (_vm.settingShowAutoComplete = (0, _concat.default)(_context3 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context3, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShowAutoComplete = $$c;
        }
      }
    }
  })]), _vm.themeSupportsMonospace ? _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_use_monospace')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingUseMonospace,
      expression: "settingUseMonospace"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingUseMonospace) ? _vm._i(_vm.settingUseMonospace, null) > -1 : _vm.settingUseMonospace
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingUseMonospace,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingUseMonospace = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context4;
            $$i > -1 && (_vm.settingUseMonospace = (0, _concat.default)(_context4 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context4, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingUseMonospace = $$c;
        }
      }
    }
  })]) : _vm._e(), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v("Escribir usando "), _c('strong', [_vm._v("tipografia en negrita")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingsBoldFont,
      expression: "settingsBoldFont"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingsBoldFont) ? _vm._i(_vm.settingsBoldFont, null) > -1 : _vm.settingsBoldFont
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingsBoldFont,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingsBoldFont = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context5;
            $$i > -1 && (_vm.settingsBoldFont = (0, _concat.default)(_context5 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context5, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingsBoldFont = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v("Escribir usando "), _c('em', [_vm._v("tipografia en cursiva")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingsItalicFont,
      expression: "settingsItalicFont"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingsItalicFont) ? _vm._i(_vm.settingsItalicFont, null) > -1 : _vm.settingsItalicFont
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingsItalicFont,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingsItalicFont = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context6;
            $$i > -1 && (_vm.settingsItalicFont = (0, _concat.default)(_context6 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context6, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingsItalicFont = $$c;
        }
      }
    }
  })]), _vm.canRegisterProtocolHandler ? _c('div', {
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    }
  }, [_c('a', {
    staticClass: "u-button u-button-primary",
    on: {
      "click": function click($event) {
        return _vm.makeDefaultProtocolHandler();
      }
    }
  }, [_c('i', [_vm._v(_vm._s(_vm.$t('settings_default_handler')))])])]) : _vm._e()])]), _c('div', {
    staticClass: "kiwi-appsettings-block"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('settings_messages_title')))]), _c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-messages"
  }, [_c('label', {
    staticClass: "kiwi-appsettings-messagelistDisplay"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_messagelayout')) + " ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingMessageLayout,
      expression: "settingMessageLayout"
    }],
    on: {
      "change": function change($event) {
        var _context7;
        var $$selectedVal = (0, _map.default)(_context7 = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context7, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.settingMessageLayout = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "traditional"
    }
  }, [_vm._v("Traditional")]), _c('option', {
    attrs: {
      "value": "modern"
    }
  }, [_vm._v("Modern")]), _c('option', {
    attrs: {
      "value": "inline"
    }
  }, [_vm._v("Inline")])])]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_timestamps')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferShowTimestamps,
      expression: "settingBufferShowTimestamps"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferShowTimestamps) ? _vm._i(_vm.settingBufferShowTimestamps, null) > -1 : _vm.settingBufferShowTimestamps
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferShowTimestamps,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferShowTimestamps = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context8;
            $$i > -1 && (_vm.settingBufferShowTimestamps = (0, _concat.default)(_context8 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context8, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferShowTimestamps = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_24hour_timestamps')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.timestamps_24h,
      expression: "timestamps_24h"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.timestamps_24h) ? _vm._i(_vm.timestamps_24h, null) > -1 : _vm.timestamps_24h
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.timestamps_24h,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.timestamps_24h = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context9;
            $$i > -1 && (_vm.timestamps_24h = (0, _concat.default)(_context9 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context9, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.timestamps_24h = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_emoticons')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferShowEmoticons,
      expression: "settingBufferShowEmoticons"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferShowEmoticons) ? _vm._i(_vm.settingBufferShowEmoticons, null) > -1 : _vm.settingBufferShowEmoticons
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferShowEmoticons,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferShowEmoticons = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context10;
            $$i > -1 && (_vm.settingBufferShowEmoticons = (0, _concat.default)(_context10 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context10, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferShowEmoticons = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_block_private')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferBlockPms,
      expression: "settingBufferBlockPms"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferBlockPms) ? _vm._i(_vm.settingBufferBlockPms, null) > -1 : _vm.settingBufferBlockPms
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferBlockPms,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferBlockPms = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context11;
            $$i > -1 && (_vm.settingBufferBlockPms = (0, _concat.default)(_context11 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context11, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferBlockPms = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "kiwi-appsettings-full kiwi-appsettings-setting-scrollback"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferScrollbackSize,
      expression: "settingBufferScrollbackSize"
    }],
    staticClass: "u-input",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.settingBufferScrollbackSize
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.settingBufferScrollbackSize = $event.target.value;
      }
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('settings_scrollback')) + " ")])]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_formatting')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferExtraFormatting,
      expression: "settingBufferExtraFormatting"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferExtraFormatting) ? _vm._i(_vm.settingBufferExtraFormatting, null) > -1 : _vm.settingBufferExtraFormatting
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferExtraFormatting,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferExtraFormatting = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context12;
            $$i > -1 && (_vm.settingBufferExtraFormatting = (0, _concat.default)(_context12 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context12, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferExtraFormatting = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_nick_colouring')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferColourNicknames,
      expression: "settingBufferColourNicknames"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferColourNicknames) ? _vm._i(_vm.settingBufferColourNicknames, null) > -1 : _vm.settingBufferColourNicknames
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferColourNicknames,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferColourNicknames = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context13;
            $$i > -1 && (_vm.settingBufferColourNicknames = (0, _concat.default)(_context13 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context13, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferColourNicknames = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_share_typing')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferShareTyping,
      expression: "settingBufferShareTyping"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferShareTyping) ? _vm._i(_vm.settingBufferShareTyping, null) > -1 : _vm.settingBufferShareTyping
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferShareTyping,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferShareTyping = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context14;
            $$i > -1 && (_vm.settingBufferShareTyping = (0, _concat.default)(_context14 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context14, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferShareTyping = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_show_inline_previews')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferInlineLinkPreviews,
      expression: "settingBufferInlineLinkPreviews"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferInlineLinkPreviews) ? _vm._i(_vm.settingBufferInlineLinkPreviews, null) > -1 : _vm.settingBufferInlineLinkPreviews
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferInlineLinkPreviews,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferInlineLinkPreviews = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context15;
            $$i > -1 && (_vm.settingBufferInlineLinkPreviews = (0, _concat.default)(_context15 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context15, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferInlineLinkPreviews = $$c;
        }
      }
    }
  })])])]), _c('div', {
    staticClass: "kiwi-appsettings-block"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('notifications')))]), _c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-notifications"
  }, [_c('label', {
    staticClass: "kiwi-appsettings-setting-showjoinpart u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_show_joinpart')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferTrafficAsActivity,
      expression: "settingBufferTrafficAsActivity"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferTrafficAsActivity) ? _vm._i(_vm.settingBufferTrafficAsActivity, null) > -1 : _vm.settingBufferTrafficAsActivity
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferTrafficAsActivity,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferTrafficAsActivity = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context16;
            $$i > -1 && (_vm.settingBufferTrafficAsActivity = (0, _concat.default)(_context16 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context16, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferTrafficAsActivity = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_mute_sound')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingBufferMuteSound,
      expression: "settingBufferMuteSound"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingBufferMuteSound) ? _vm._i(_vm.settingBufferMuteSound, null) > -1 : _vm.settingBufferMuteSound
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingBufferMuteSound,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingBufferMuteSound = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context17;
            $$i > -1 && (_vm.settingBufferMuteSound = (0, _concat.default)(_context17 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context17, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingBufferMuteSound = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "kiwi-appsettings-full"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_highlight')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingHighlights,
      expression: "settingHighlights"
    }],
    staticClass: "u-input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.settingHighlights
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.settingHighlights = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "kiwi-appsettings-block"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('operator_tools')))]), _c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-operator-tools"
  }, [_c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('settings_default_ban_mask')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingDefaultBanMask,
      expression: "settingDefaultBanMask"
    }],
    staticClass: "u-input",
    domProps: {
      "value": _vm.settingDefaultBanMask
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.settingDefaultBanMask = $event.target.value;
      }
    }
  })]), _c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('settings_default_kick_reason')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingDefaultKickReason,
      expression: "settingDefaultKickReason"
    }],
    staticClass: "u-input",
    domProps: {
      "value": _vm.settingDefaultKickReason
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.settingDefaultKickReason = $event.target.value;
      }
    }
  })])])]), !_vm.$state.setting('hide_advanced') && !_vm.settingAdvancedEnable ? _c('div', {
    staticClass: "kiwi-appsettings-block"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('settings_advanced_title')))]), _c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-advanced-enable"
  }, [_c('div', [_c('span', {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('warning')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('settings_advanced_warning')) + " ")]), _c('div', {
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    }
  }, [_c('a', {
    staticClass: "u-button u-button-warning",
    on: {
      "click": function click($event) {
        return _vm.enableAdvancedTab();
      }
    }
  }, [_c('i', [_vm._v(_vm._s(_vm.$t('settings_advanced_button')))])])])])]) : _vm._e()]), _c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('settings_aliases'),
      "name": "aliases"
    }
  }, [_c('div', {
    staticClass: "kiwi-appsettings-block kiwi-appsettings-block-aliases"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('settings_aliases')))]), _c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-aliases"
  }, [_c('settings-aliases')], 1)])]), _vm.settingAdvancedEnable ? _c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('settings_advanced'),
      "name": "advanced"
    }
  }, [_c('div', {
    staticClass: "kiwi-appsettings-block kiwi-appsettings-block-advanced"
  }, [_c('div', {
    staticClass: "kiwi-appsettings-section kiwi-appsettings-advanced"
  }, [_c('settings-advanced')], 1)])]) : _vm._e(), _vm._l(_vm.pluginUiElements, function (item) {
    return _c('tabbed-tab', {
      key: item.id,
      attrs: {
        "header": item.title(),
        "name": item.tabName
      }
    }, [_c(item.component, _vm._b({
      tag: "component"
    }, 'component', item.props, false))], 1);
  })], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;