"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-inputtool-colours"
  }, [_c('div', {
    staticClass: "kiwi-inputtool-colours-palette",
    on: {
      "mousedown": function mousedown($event) {
        $event.preventDefault();
      },
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-white",
    attrs: {
      "data-code": "00"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-black",
    attrs: {
      "data-code": "01"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-blue",
    attrs: {
      "data-code": "02"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-green",
    attrs: {
      "data-code": "03"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-light-red",
    attrs: {
      "data-code": "04"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-brown",
    attrs: {
      "data-code": "05"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-purple",
    attrs: {
      "data-code": "06"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-orange",
    attrs: {
      "data-code": "07"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-yellow",
    attrs: {
      "data-code": "08"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-light-green",
    attrs: {
      "data-code": "09"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-cyan",
    attrs: {
      "data-code": "10"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-light-cyan",
    attrs: {
      "data-code": "11"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-light-blue",
    attrs: {
      "data-code": "12"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-pink",
    attrs: {
      "data-code": "13"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-grey",
    attrs: {
      "data-code": "14"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour irc-bg-colour-light-grey",
    attrs: {
      "data-code": "15"
    },
    on: {
      "click": _vm.onColourClick
    }
  }), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour kiwi-inputtools-bold",
    staticStyle: {
      "font-weight": "normal"
    },
    attrs: {
      "id": "bold"
    },
    on: {
      "click": _vm.onBoldClick
    }
  }, [_vm._v("B")]), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour kiwi-inputtools-bold",
    staticStyle: {
      "font-weight": "normal"
    },
    attrs: {
      "id": "italic"
    },
    on: {
      "click": _vm.onItalicClick
    }
  }, [_c('em', [_vm._v("I")])]), _c('div', {
    staticClass: "kiwi-inputtools-colours-colour kiwi-inputtools-colours-reset",
    on: {
      "click": _vm.onResetClick
    }
  }, [_c('i', {
    staticClass: "fa fa-ban",
    attrs: {
      "aria-hidden": "true"
    }
  })])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;