"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/toConsumableArray"));
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/values"));
var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));
var _lodash = _interopRequireDefault(require("lodash"));
var htmlparser = _interopRequireWildcard(require("htmlparser2"));
var Colours = _interopRequireWildcard(require("@/helpers/Colours"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var EmojiProvider = _interopRequireWildcard(require("@/libs/EmojiProvider"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var Vue = require('vue');
var _default = Vue.component('irc-input', {
  props: ['placeholder'],
  data: function data() {
    return {
      last_known_value: '',
      text_value: '',
      current_el: null,
      current_el_pos: 0,
      default_colour: null,
      code_map: Object.create(null),
      style: {
        fgColour: null,
        bgColour: null,
        bold: false,
        italic: false,
        underline: false,
        strikethrough: false
      }
    };
  },
  computed: {
    editor: function editor() {
      return this.$refs.editor;
    }
  },
  mounted: function mounted() {
    this.resetStyles();
  },
  methods: {
    onTextInput: function onTextInput(event) {
      // Mobile devices trigger a textInput event for things such as autocompletion
      // and suggested words. Unfortunately they end with a return character which
      // is not what we expect, so prevent the original event from inserting anything
      // and manually place it in with the current word.
      if (event.data[event.data.length - 1] === '\n') {
        var _context;
        event.preventDefault();
        this.setCurrentWord((0, _trim.default)(_context = event.data).call(_context));
      }
    },
    onPaste: function onPaste(event) {
      var _this = this;
      event.preventDefault();
      if (typeof event.clipboardData !== 'undefined') {
        var ignoreThisPaste = false;
        var clpData = event.clipboardData;
        clpData.types.forEach(function (type) {
          var ignoreTypes = ['Files', 'image'];
          ignoreTypes.forEach(function (ig) {
            if (type.indexOf(ig) > -1) {
              ignoreThisPaste = true;
            }
          });
        });
        if (ignoreThisPaste) {
          return;
        }
        document.execCommand('insertText', false, clpData.getData('text/plain'));
      } else {
        // IE11
        var clpText = window.clipboardData.getData('Text');
        if (!clpText) {
          return;
        }
        var selection = window.getSelection();
        var range = selection.getRangeAt(0);
        if (range) {
          range.deleteContents();
          range.insertNode(document.createTextNode(clpText));
        }
      }
      setTimeout(function () {
        _this.updateValueProps();
      }, 0);
    },
    onDrop: function onDrop(event) {
      // Allow dragging of single emoji's from the message list
      var raw = event.dataTransfer.getData('text/html');
      if (!raw) {
        return;
      }
      var html = document.createElement('div');
      html.innerHTML = raw;
      if (html.childNodes.length === 0 || html.childNodes.length > 1) {
        return;
      }
      var node = html.childNodes[0];
      if (node instanceof HTMLImageElement && node.classList.contains('kiwi-messagelist-emoji')) {
        var _context2;
        event.preventDefault();
        var emojis = EmojiProvider.getEmojis((0, _trim.default)(_context2 = node.alt).call(_context2));
        if (!emojis.length) {
          return;
        }
        this.addImg(emojis[0].ascii, emojis[0].url, emojis[0].imgProps);
      }
    },
    onFocus: function onFocus(event) {
      // Chrome sometimes focus' the element but does not add the cursor
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1125078
      this.focus();

      // when the input is empty there are no children to remember the current colour
      // so upon regaining focus we must set the current colour again
      if (!this.getRawText() && this.default_colour) {
        this.setColour(this.default_colour.code, this.default_colour.colour);
      }
      this.$emit('focus', event);
    },
    updateSpacing: function updateSpacing() {
      var editor = this.$refs.editor;
      if (!editor) {
        return;
      }

      // This will make sure any image nodes are padded with spaces
      editor.childNodes.forEach(function (node) {
        var _context3;
        if (node.nodeName !== 'IMG') {
          return;
        }
        node.alt = (0, _trim.default)(_context3 = node.alt).call(_context3);
        if (node.previousSibling) {
          var _context4;
          var text = node.previousSibling.textContent.replace(/\xA0/g, ' ');
          var isEmpty = text.length === 0;
          var isImg = node.previousSibling.nodeName === 'IMG';
          var isText = (0, _includes.default)(_context4 = ['#text', 'SPAN']).call(_context4, node.previousSibling.nodeName);
          if (isImg || isText && (isEmpty || text[text.length - 1].indexOf(' ') === -1)) {
            // The previous node is an image or text and does not end with a space
            node.alt = ' ' + node.alt;
          }
        }
        if (node.nextSibling) {
          var _context5;
          var _text = node.nextSibling.textContent.replace(/\xA0/g, ' ');
          var isNeeded = _text.length !== 0 || !node.nextSibling.nextSibling;
          var _isText = (0, _includes.default)(_context5 = ['#text', 'SPAN']).call(_context5, node.nextSibling.nodeName);
          if (_isText && isNeeded && (_text.length === 0 || _text[0].indexOf(' ') === -1)) {
            // The next node is text and does not start with a space
            node.alt += ' ';
          }
        }
      });
    },
    updateValueProps: function updateValueProps() {
      if (!this.$el.contains(document.activeElement)) {
        // Focused element is not a child of IrcInput
        // selection would not be relevant
        return;
      }
      var selection = window.getSelection();
      if (selection.rangeCount === 0) {
        return;
      }
      this.current_el_pos = selection.anchorOffset;
      this.current_el = selection.anchorNode;
      this.maybeEmitInput();
    },
    selectionToEnd: function selectionToEnd() {
      // Move the caret to the end
      var el = this.$refs.editor.lastChild || this.$refs.editor;
      this.current_el = el;
      if (el.nodeType === 3) {
        this.current_el_pos = el.length;
      } else if (this.$el.contains(document.activeElement)) {
        // IrcInput has focus select all content and collapse to end
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
        this.updateValueProps();
      } else {
        this.current_el_pos = 0;
      }
    },
    setValue: function setValue(newVal) {
      this.value = newVal;
      this.$refs.editor.innerHTML = newVal;
    },
    getValue: function getValue() {
      return this.$refs.editor.innerHTML;
    },
    maybeEmitInput: function maybeEmitInput() {
      var currentHtml = this.$refs.editor.innerHTML;
      if (this.last_known_value !== currentHtml) {
        this.$emit('input', currentHtml);
        this.last_known_value = currentHtml;
      }
    },
    buildIrcText: function buildIrcText() {
      var _this2 = this,
        _context6;
      this.updateSpacing();
      var source = this.$refs.editor.innerHTML;
      var textValue = '';

      // Toggles are IRC style and colour codes that should be reset at the end of
      // the current tag
      var toggles = [];
      function addToggle(t) {
        toggles[toggles.length - 1] += t;
      }
      function getToggles() {
        return toggles[toggles.length - 1];
      }
      var parser = new htmlparser.Parser({
        onopentag: function onopentag(name, attribs) {
          toggles.push('');
          var codeLookup = '';
          if (attribs.style) {
            var match = attribs.style.match(/color: ([^;]+)/);
            if (match) {
              codeLookup = match[1];
              var mappedCode = _this2.code_map[codeLookup];
              if (!mappedCode) {
                // If we didn't have an IRC code for this colour, convert the
                // colour to its hex form and check if we have that instead
                var m = codeLookup.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
                if (m) {
                  var hex = Colours.rgb2hex({
                    r: parseInt(m[1], 10),
                    g: parseInt(m[2], 10),
                    b: parseInt(m[3], 10)
                  });
                  mappedCode = _this2.code_map[hex];
                }
              }
              if (mappedCode) {
                textValue += '\x03' + mappedCode;
                addToggle('\x03' + mappedCode);
              }
            }
            if (attribs.style.indexOf('bold') > -1) {
              textValue += '\x02';
              addToggle('\x02');
            }
            if (attribs.style.indexOf('italic') > -1) {
              textValue += '\x1d';
              addToggle('\x1d');
            }
            if (attribs.style.indexOf('underline') > -1) {
              textValue += '\x1f';
              addToggle('\x1f');
            }

            // Welcome to the IE/Edge sucks section, time to do crazy things
            // IE11 doesnt support document.execCommand('styleWithCSS')
            // so we have individual nodes instead, which are handled below
          } else if (attribs.color) {
            // IE likes to remove spaces from rgb(1, 2, 3)
            // it also likes converting rgb to hex
            var _mappedCode = _this2.code_map[attribs.color] || _this2.code_map[attribs.color.replace(/,/g, ', ')] || _this2.code_map[Colours.hex2rgb(attribs.color)];
            if (_mappedCode) {
              textValue += '\x03' + _mappedCode;
              addToggle('\x03' + _mappedCode);
            }
          } else if (name === 'strong') {
            textValue += '\x02';
            addToggle('\x02');
          } else if (name === 'em') {
            textValue += '\x1d';
            addToggle('\x1d');
          } else if (name === 'u') {
            textValue += '\x1f';
            addToggle('\x1f');
          } else if (name === 'div' || name === 'br') {
            // divs and breaks are both considered newlines. For each line we need to
            // close all current toggles and then reopen them for the next so that the
            // styles continue .
            textValue += getToggles();
            textValue += '\n';
            textValue += getToggles();
          }
          if (name === 'img' && attribs.alt) {
            textValue += attribs.alt;
          }
        },
        ontext: function ontext(text) {
          textValue += text;
        },
        onclosetag: function onclosetag(tagName) {
          textValue += getToggles();
          toggles.pop();
        }
      }, {
        decodeEntities: true
      });
      parser.write(source);
      parser.end();

      // Firefox likes to add <br/> at the end (some times inside the span)
      // fix by filtering out any lines that contain no content
      return (0, _filter.default)(_context6 = textValue.split(/\r?\n/)).call(_context6, function (line) {
        return !!Misc.stripStyles(line);
      }).join('\n');
    },
    reset: function reset(rawHtml, shouldFocus) {
      this.$refs.editor.innerHTML = rawHtml || '';
      this.current_el_pos = 0;
      this.current_el = this.$refs.editor;

      // Firefox inserts a <br> on empty contenteditables after it's been reset. But that
      // fucks up the placeholder :empty CSS selector we use. So just remove it.
      var br = this.$refs.editor.querySelector('br');
      if (br) {
        br.parentNode.removeChild(br);
      }
      if (shouldFocus) {
        this.focus();
        if (this.default_colour) {
          this.setColour(this.default_colour.code, this.default_colour.colour);
        }
        if (this.$state.setting('boldFont')) {
          if (document.queryCommandState('bold') !== this.$state.setting('boldFont')) {
            document.execCommand('bold', false, null);
          }
        }
        if (this.$state.setting('italicFont')) {
          if (document.queryCommandState('italic') !== this.$state.setting('italicFont')) {
            document.execCommand('italic', false, null);
          }
        }
        this.updateValueProps();
      } else {
        this.maybeEmitInput();
      }
    },
    resetStyles: function resetStyles() {
      this.focus();
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('selectAll', false, null);
      // document.execCommand('removeFormat', false, null);
      // this.default_colour = null;

      if (this.$state.setting('fontColor')) {
        var code = this.$state.setting('fontColor');
        var colour = this.$state.setting('fontColorColour');
        this.default_colour = {
          code: code,
          colour: colour
        };
      } else {
        this.default_colour = null;
      }
      if (this.$state.setting('fontColor')) {
        this.setColour(this.$state.setting('fontColor'), this.$state.setting('fontColorColour'));
      }
      if (this.$state.setting('boldFont')) {
        if (document.queryCommandState('bold') !== this.$state.setting('boldFont')) {
          document.execCommand('bold', false, null);
        }
      }
      if (this.$state.setting('italicFont')) {
        if (document.queryCommandState('italic') !== this.$state.setting('italicFont')) {
          document.execCommand('italic', false, null);
        }
      }
    },
    setColour: function setColour(code, colour) {
      // If no current text selection, set this colour as the default colour for
      // future messages too
      var range = window.getSelection().getRangeAt(0);
      if (range && range.collapsed) {
        this.default_colour = {
          code: code,
          colour: colour
        };
      }
      this.focus();
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('foreColor', false, colour);
      if (this.$state.setting('boldFont')) {
        if (document.queryCommandState('bold') !== this.$state.setting('boldFont')) {
          document.execCommand('bold', false, null);
        }
      }
      if (this.$state.setting('italicFont')) {
        if (document.queryCommandState('italic') !== this.$state.setting('italicFont')) {
          document.execCommand('italic', false, null);
        }
      }
      this.code_map[colour] = code;
      this.updateValueProps();
    },
    updateStyles: function updateStyles() {
      document.execCommand('styleWithCSS', false, true);
      if (this.$state.setting('fontColor')) {
        document.execCommand('foreColor', false, this.$state.setting('fontColorColour'));
        this.code_map[this.$state.setting('fontColorColour')] = this.$state.setting('fontColor');
      } else {
        var selection = document.getSelection();
        var parent = selection.anchorNode.parentElement;
        if (parent.nodeName === 'SPAN' && parent.style.color) {
          document.execCommand('foreColor', false, 'inherit');
        }
      }

      /*
      if (this.style.bgColour) {
          document.execCommand('backColor', false, this.style.bgColour.hex);
          this.code_map[this.style.bgColour.hex] = this.style.bgColour.code;
      } else {
          const selection = document.getSelection();
          const parent = selection.anchorNode.parentElement;
          if (parent.nodeName === 'SPAN' && parent.style.backgroundColor) {
              document.execCommand('backColor', false, 'inherit');
          }
      }
      */

      /*
      if (this.$state.setting('boldFont') !== document.queryCommandState('bold')) {
          document.execCommand('bold', false, null);
      }
       if (this.$state.setting('italicFont') !== document.queryCommandState('italic')) {
          document.execCommand('italic', false, null);
      }
      */
    },
    toggleBold: function toggleBold() {
      document.execCommand('bold', false, null);
      this.updateValueProps();
    },
    toggleItalic: function toggleItalic() {
      document.execCommand('italic', false, null);
      this.updateValueProps();
    },
    toggleUnderline: function toggleUnderline() {
      document.execCommand('underline', false, null);
      this.updateValueProps();
    },
    addImg: function addImg(alt, url, props) {
      this.focus();
      var existingImages = (0, _toConsumableArray2.default)((0, _values.default)(_lodash.default).call(_lodash.default, this.$refs.editor.querySelectorAll('img')));
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('insertImage', false, url);
      var newImg = null;
      var images = (0, _toConsumableArray2.default)((0, _values.default)(_lodash.default).call(_lodash.default, this.$refs.editor.querySelectorAll('img')));

      // Find image that has just been inserted
      images.forEach(function (img) {
        if (existingImages.indexOf(img) === -1) {
          newImg = img;
        }
      });
      newImg.alt = alt;
      (0, _assign.default)(newImg, props);

      // Find the position of this new image node
      var prevElCnt = 0;
      var el = newImg;
      while (el) {
        el = el.previousSibling;
        prevElCnt++;
      }
      this.current_el = this.$refs.editor;
      this.current_el_pos = prevElCnt;
      this.updateValueProps();
      this.focus();
    },
    // Insert some text at the current position
    insertText: function insertText(text) {
      this.focus();
      document.execCommand('insertText', false, text);
      this.updateValueProps();
      this.focus();
    },
    // Replace the word at the current position with another
    setCurrentWord: function setCurrentWord(text, keepPosition, toPosition) {
      var el = this.current_el;
      var pos = this.current_el_pos;
      var val = el.textContent || '';
      var startVal = val.substr(0, pos);
      var space = startVal.replace(/\xA0/g, ' ').lastIndexOf(' ');
      if (space === -1) {
        space = 0;
      } else {
        // include the space after the word
        space++;
      }
      var startPos = space;
      var endVal = val.substr(pos);
      space = endVal.replace(/\xA0/g, ' ').indexOf(' ');
      if (space === -1) space = endVal.length;
      var endPos = toPosition ? 0 : space;
      el.textContent = startVal.substr(0, startPos) + text + endVal.substr(endPos);

      // Move the caret after the word
      var range = document.createRange();
      if (keepPosition) {
        range.setStart(el, pos);
        range.setEnd(el, pos);
      } else if (el.nodeType === 3) {
        // TEXT_NODE
        range.setStart(el, startPos + text.length);
        range.setEnd(el, startPos + text.length);
      } else {
        // el is another type of node, so setStart/End() counts in nodes instead
        // of text length
        range.setStart(el, 1);
        range.setEnd(el, 1);
      }

      // ensure the input has focus so the cursor position can be set correctly
      this.$refs.editor.focus();
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      this.updateValueProps();
    },
    getCurrentWord: function getCurrentWord(toPosition) {
      var el = this.current_el;
      var pos = this.current_el_pos;
      var val = el.textContent;
      var cleanVal = val.replace(/\xA0/g, ' ');
      var startVal = cleanVal.substr(0, pos);
      var space = startVal.lastIndexOf(' ');
      if (space === -1) {
        space = 0;
      } else {
        // include the space after the word
        space++;
      }
      var startPos = space;
      space = cleanVal.indexOf(' ', startPos);
      if (space === -1) {
        space = val.length;
      }
      var endPos = toPosition ? pos - startPos : space;
      return {
        word: val.substr(startPos, endPos),
        position: pos - startPos
      };
    },
    getCaretIdx: function getCaretIdx() {
      var position = 0;
      var selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        var range = window.getSelection().getRangeAt(0);
        var caretRange = range.cloneRange();
        caretRange.selectNodeContents(this.$el);
        caretRange.setEnd(range.endContainer, range.endOffset);
        position = caretRange.toString().length;
      }
      return position;
    },
    getRawText: function getRawText() {
      return this.$refs.editor.innerText;
    },
    // Focus the editable div and move the caret to the end
    focus: function focus() {
      var selection = window.getSelection();
      var range = document.createRange();
      range.setStart(this.current_el || this.$refs.editor, this.current_el_pos || 0);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
});
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["IrcInput"]
window._kiwi_exports.components.utils.IrcInput = exports.default ? exports.default : exports;
